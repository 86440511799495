.c-clients {
  background-size: cover;
  background-attachment: fixed;
  padding: 2.5rem 0;

  @include bp(medium) {
    padding: 3.5rem 0;
  }

  &__title {
    padding-top: 2.5rem;

    @include bp(medium) {
      padding-top: 3.5rem;
    }
  }

  &__item {
    margin: 1rem;
    border-top: 1px solid;
  }

  &__link {
    text-decoration: none;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &::after {
      content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='1' stroke-linecap='butt' stroke-linejoin='arcs'%3E%3Cpath d='M7 17l9.2-9.2M17 17V7H7'/%3E%3C/svg%3E");
      height: 24px;
      width: 24px;
    }

    &:hover {
      color: currentColor;
      text-decoration: underline;
    }
  }
}
