.c-page {
  padding-top: 10vh;
  overflow-x: hidden;

  &__body {
    > *:not(.wp-block-cover):not(.wp-block-pullquote):not(.wp-block-spacer) {
      @extend %wp-gutenberg-item-wrap;
    }

    > p {
      @extend %u-width--half;
    }
  }

  &__title {
    margin-bottom: 10vh;
  }

  img {
    height: auto;
  }
}

.c-page--404 {
  min-height: 100vh;
  padding-top: 33vh;
}

.c-page-split {
  .c-page__body {
    margin: 0;

    @include bp(large) {
      position: absolute;

      > * {
        padding-left: 20px;
        padding-right: 20px;
        margin-left: 0 !important;
        margin-right: 0 !important;
      }

      > *:last-child {
        padding-bottom: 4rem;
      }
    }
  }

  .c-page__meta {
    position: relative;

    + *::before {
      content: '';
      display: block;
      height: 1px;
      background: $color-peach;
      margin-bottom: 2rem;
    }
  }

  .c-page__meta-item {
    font-size: 1em;
    font-weight: 300;
  }

  .c-page__media-trigger {
    position: fixed;
    top: calc(100vh - 50px);
    left: calc(50vw - 50px);
    z-index: 100;
  }

  .c-page__media {
    @include bp(large) {
      height: 100vh;
      overflow-y: scroll;
      position: fixed;
      right: 0;
      top: 0;

      /* Hide scrollbar for Chrome, Safari and Opera */
      &::-webkit-scrollbar {
        display: none;
      }

      /* Hide scrollbar for IE, Edge and Firefox */
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
  }

  .c-page__media-item {
    width: 100%;
    min-height: 100vh;
    position: absolute;
    display: flex;
    align-items: center;
    top: 0;

    &:first-child {
      object-fit: cover;
    }

    &:not(:first-child) {
      overflow: hidden;
      opacity: 0;
      height: 0;
    }

    img {
      width: 100%;
      height: 100vh;
      object-fit: cover;
    }

    .vjs-tech {
      object-fit: cover;
    }
  }
}

.page-template-pagetemplate-list-children {
  a[href='#'] {
    text-decoration: none;
    align-items: center;

    &::before {
      content: '';
      display: block;
      height: 1px;
      width: 100%;
      position: absolute;
      bottom: -0.25em;
      left: 0;
      transition: $global-transition;
    }
  }

  .js-active {
    padding-left: 1.25em;

    &::before {
      bottom: -0.25em !important;
      width: 0 !important;
    }

    &::after {
      content: '˟';
      display: block;
      height: 1em;
      width: 1em;
      position: absolute;
      top: 0.525em;
      left: 0;
      font-size: 2em;
      line-height: 0;
      color: #999;
    }
  }

  .leadership,
  .design-innovation-technology,
  .tdc-special-projects {
    > a::before {
      background: $color-red;
    }
  }

  .finance,
  .bodies-institutions {
    > a::before {
      background: $color-purple;
    }
  }

  .pr-and-communications,
  .creative-design-agencies {
    > a::before {
      background: $color-yellow;
    }
  }

  .accounts-team,
  .architecture-interiors {
    > a::before {
      background: $color-green;
    }
  }

  .social-media,
  .brands {
    > a::before {
      background: $color-peach;
    }
  }
}
