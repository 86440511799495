/* ==========================================================================
   #LINKS
   ========================================================================== */

a {
  color: $color-link;
  text-decoration: none;
  transition: $global-transition;
  position: relative;

  @media (any-pointer: fine) {
    cursor: none !important;
  }

  &:hover {
    color: $color-hover;
  }
}

a:not(.naked) {
  main p & {
    border-bottom: 1px solid $color-orange;
  }
}
