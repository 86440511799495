%u-collapse {
  overflow: hidden;
  opacity: 0;
  height: 0;
}

.c-reel {
  &__titles {
    @extend %u-pos--index-5;
    @extend %u-pos--abs;

    width: calc(100% - 7rem);
    bottom: 2.5rem;
    left: 2.5rem;
    max-width: 650px;

    @include bp(medium) {
      bottom: 3.5rem;
      left: 3.5rem;
    }
  }

  &__title {
    transition: $global-transition;
    color: $color-white;
    margin-bottom: 0;
    position: relative;
    opacity: 0;
    left: 1em;
  }

  &__link {
    @extend %u-pos--abs;

    transition: $global-transition;
    font-size: 1rem;
    top: -2rem;
    margin-bottom: 0;
    opacity: 0;
    left: 1em;
  }

  &__link-text {
    text-decoration: none;
    color: $color-orange;
    border-bottom: 1px solid $color-orange;
    display: inline-block;
  }

  &__homepage {
    @extend %u-pos--index-0;
    @extend %u-pos--fixed;
    @extend %u-pos--tl;

    height: 100vh;
    width: 100vw;
  }

  .vjs-tech {
    object-fit: cover;
  }

  .vjs-poster {
    background-size: cover;
  }
}

.js-reel-link--active.c-reel__link {
  opacity: 1;
  left: 0;
}

.js-reel-link--exit.c-reel__link {
  opacity: 0;
  left: -0.5em;
}

.js-reel-title--active.c-reel__title {
  transition-duration: 0.5s;
  transition-delay: 0.33s;
  opacity: 1;
  left: 0;
}
