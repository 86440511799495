/* ==========================================================================
   #WORDPRESS OVERRIDES
   ========================================================================== */

.wp-block-pullquote {
  border-style: solid;
  border-width: 1px;
  border-left: none;
  border-right: none;
}

.wp-block-cover.is-position-center-left {
  min-height: 100vh !important;
  padding: 0 !important;

  .wp-block-cover__inner-container {
    max-width: 1366px;
    padding: 0 3.5rem;
    width: 100% !important;
    margin: 0 auto !important;

    &::before {
      content: '';
      display: block;
      margin-bottom: 2rem;
      width: 25%;
      border-bottom: 1px solid;
    }
  }

  p {
    font-size: 2.25rem;
    font-weight: 400;
    line-height: 1.2;
    max-width: 585px;
  }

  a {
    color: $color-orange;
  }
}

.wp-block-cover,
.wp-block-cover-image {
  padding: 0 !important;
}

.wp-block-column > *:last-child {
  margin-bottom: 0;
}

.wp-block-pullquote.has-background.is-style-solid-color {
  min-height: 100vh;
  display: flex;
  align-items: center;
  margin-bottom: 3.5rem;

  blockquote {
    quotes: none;
    max-width: 1366px;
    padding: 0 3.5rem;
    width: 100%;

    &::before {
      content: '';
      display: block;
      margin-bottom: 2rem;
      width: 25%;
      border-bottom: 1px solid;
    }

    p {
      font-size: 2.25rem;
      font-weight: 400;
      line-height: 1.2;
      max-width: 585px;
    }
  }
}

.wp-block-separator {
  border-bottom: none !important;
}

%wp-gutenberg-item-wrap,
.wp-gutenberg-item-wrap {
  padding: 0 2.5rem;
  max-width: 1366px;
  margin: 0 auto 2.5rem !important;

  @include bp(medium) {
    margin: 0 auto 3.5rem !important;
    padding: 0 3.5rem;
  }
}

.wp-toggle-title {
  position: absolute;
  width: calc(100% - 45px);
  z-index: -1;
}

.wp-toggle-icon-col {
  flex-basis: 100% !important;
}

.wp-toggle-icon {
  display: inline-block;
  height: 38px;
  width: 100%;
  text-align: right;
}

.wp-toggle-icon > svg {
  transition: all 0.25s ease-out;
}

/* stylelint-disable */
.wp-toggle {
  display: none;

  &:checked {
    + .wp-toggle-icon > svg {
      transform: rotate(45deg);
    }

    + .wp-toggle-icon + * {
      height: auto;
      opacity: 1;
      margin-top: 2rem;

      @include bp(medium) {
        margin-top: 1rem;
      }
    }
  }

  + .wp-toggle-icon + * {
    height: 0;
    overflow: hidden;
    opacity: 0;
    transition: all 0.25s ease-out, opacity 0.6s ease-out;
  }
}
/* stylelint-enable */
