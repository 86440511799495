@mixin linktransitiondefault {
  transition: $global-transition;
  transition-duration: 150ms;
  transform: translateX(30px);
  opacity: 0;
}

@mixin linktransitionhovered {
  transition-duration: 300ms;
  transition-delay: calc(0ms + var(--link-index) * 150ms);
  transform: translateX(0);
  opacity: 1;
}
