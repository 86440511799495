.c-project {
  &__header {
    @include bp(large) {
      height: 100vh;
      overflow-x: hidden;
    }
  }

  &__header-item {
    width: 100%;

    @include bp(large) {
      height: 100vh;
      width: 50%;
    }
  }

  &__intro-item {
    margin-bottom: 0.5em;

    @include bp(large) {
      margin: 0;
    }
  }

  &__meta {
    @include bp-down(large) {
      width: 75%;
    }

    @include bp-between(large, xlarge) {
      display: block;
    }
  }

  &__link {
    padding-right: 23px;
  }

  &__link-icon {
    position: absolute;
    margin: 3px 0 0 5px;
    height: 20px;
  }

  &__feature {
    @include bp(large) {
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  &__media {
    width: 100%;
    height: 100vw;
    object-fit: cover;

    @include bp(large) {
      height: 100vh;
    }

    video {
      object-fit: cover;
    }
  }

  &__service {
    font-size: 1em;
    display: inline;
    margin-right: 1.5em;
    opacity: 0.4;
  }

  &__body {
    padding-top: 3.5rem;

    @include bp(medium) {
      padding-top: 3.5rem;
    }

    img {
      width: 100%;
      height: auto;
    }

    > *:not(.wp-block-cover):not(.wp-block-pullquote):not(.wp-block-spacer) {
      @extend %wp-gutenberg-item-wrap;
    }

    > p {
      @extend %u-width--half;
    }
  }
}

// Sector line top colours
.c-project__intro-item::before {
  .c-project--brands & {
    background-color: $color-peach;
  }

  .c-project--architecture-interiors & {
    background-color: $color-green;
  }

  .c-project--creative-design-agencies & {
    background-color: $color-yellow;
  }

  .c-project--bodies-institutions & {
    background-color: $color-purple;
  }

  .c-project--design-innovation-technology & {
    background-color: $color-red;
  }
}

// Sector line top
.c-project__meta + .c-project__intro-item::before {
  content: '';
  display: block;
  background-color: $color-orange;
  margin: 6rem 0 2rem;
  height: 1px;
  width: 50%;
}

.parallax {
  position: relative;

  .u-width--half {
    position: relative;
    left: 50%;
  }
}
