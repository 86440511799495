.mc-embed-signup-scroll > * {
  max-width: 500px;
}

.mc-embedded-subscribe-form {
  label {
    display: none;
  }

  input[type='email'] {
    width: 100%;
    padding: 1em;
  }

  &:not(.mc-footer-signup) {
    border-bottom: 1px solid $color-orange;
    border-top: 1px solid $color-orange;
    padding: 0.25em 0;

    .mc-embed-signup-scroll {
      background: $color-very-blog-grey;
      padding: 1.5em 3em;

      > * {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}

.mc-field-group {
  margin-bottom: 0.35em;
}

.mc-embedded-subscribe {
  border: none;
  margin-top: 0.5em;
}

.mc-footer-signup {
  input {
    display: inline-block;
  }

  input[type='submit'] {
    background-color: transparent;
    color: $color-orange;
    padding-bottom: calc(0.5rem + 1px);
    position: absolute;
    right: 0;
    top: 0;

    &:hover::after {
      content: '→';
    }
  }

  input[type='email'] {
    appearance: none;
    outline: none;
    border: none;
    border: 1px solid rgba($color-white, 0.1);
    border-radius: $global-radius;
    background-color: transparent;
    color: $color-white;

    &:focus {
      border-radius: $global-radius;
      background-color: rgba($color-white, 0.1);
    }

    &::placeholder {
      color: rgba($color-white, 0.5);
    }
  }
}
