%u-width,
.u-width {
  &--half {
    width: 50%;
  }

  &--three-quarters {
    width: 75%;
  }

  &--minus-menu {
    width: calc(100% - 200px);
  }
}

%u-height,
.u-height {
  &--window {
    min-height: 100vh;
  }
}
