%u-pos,
.u-pos {
  &--rel {
    position: relative;
  }

  &--abs {
    position: absolute;
  }

  &--abs--mob {
    @include bp-down(600px) {
      position: absolute;
    }
  }

  &--fixed {
    position: fixed;
  }

  &--tl {
    top: 0;
    left: 0;
  }

  &--tr {
    top: 0;
    right: 0;
  }

  &--br {
    bottom: 0;
    right: 0;
  }

  &--bl {
    bottom: 0;
    left: 0;
  }

  &--index-0 {
    z-index: 0;
  }

  &--index-5 {
    z-index: 5;
  }

  &--index-10 {
    z-index: 10;
  }
}
