.cursor {
  display: none;
}

@media (any-pointer: fine) {
  .cursor {
    position: fixed;
    top: 0;
    left: 0;
    pointer-events: none;
    z-index: 9999999999;
    height: 20px;
    width: 20px;
    background: $color-orange;
    border-radius: 50%;
    transition: opacity 0.2s ease-out, transform 0.2s ease-out;
  }

  .no-js .cursor {
    display: none;
  }
}
