/* ==========================================================================
   #MAIN NAV
   ========================================================================== */

.c-main-nav {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100px;
  min-height: calc(100vh - 130px);
}

.c-main-nav__list {
  padding: 0;
  margin: 0;
  counter-reset: css-counters 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  min-height: calc(100vh - 180px);
}

.c-main-nav__item {
  list-style: none;
  margin: 1.33333333em 0 0;
}

.c-main-nav__link {
  display: block;
  text-decoration: none;

  &::before {
    display: block;
    counter-increment: css-counters;
    content: '0' counters(css-counters, '.');
    font-size: 0.8rem !important;
    opacity: 0.66;
  }
}

.current-menu-item .c-main-nav__link {
  &::after {
    content: '';
    height: 0.3em;
    width: 0.3em;
    background: $color-orange;
    border-radius: 50%;
    bottom: 0.6em;
    left: -0.7em;
    position: absolute;
  }
}

.c-main-nav__dropdown {
  display: none;
  flex-direction: column;
  margin: 0;
  position: absolute;

  .c-main-nav__item:hover & {
    display: flex;
  }
}

.c-main-nav__subitem {
  list-style: none;
  margin: 0;
}

.c-main-nav__sublink {
  display: block;
  text-decoration: none;
}

.c-header--hidden {
  .c-main-nav__link {
    color: $color-white;

    @include linktransitiondefault;
  }

  &:hover {
    .c-main-nav__link {
      @include linktransitionhovered;
    }
  }
}
