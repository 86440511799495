.c-formula {
  text-transform: uppercase;
  text-align: center;
  font-size: 0;
  color: $color-orange;

  &__item {
    opacity: 0;
    font-size: 1.5rem;
    margin-right: 3px;
  }
}
