.c-blog {
  margin: 10vh auto 3.5rem;
  padding: 0 2.5rem;
  max-width: 1024px;

  @include bp(medium) {
    padding: 0 3.5rem;
  }

  &__section {
    margin-top: 3em;
  }

  &__nav {
    margin-bottom: 3em;
    color: $color-orange;
  }

  &__navlink {
    border: none !important;
    color: $color-orange;
  }

  &__title {
    font-size: 1.25em;
    margin-bottom: 0;
  }

  &__meta-wrapper {
    margin-bottom: 1em;
    line-height: 1;
  }

  &__divide {
    border-top: 1px solid $color-orange;
    color: $color-orange;

    &--borderless {
      border-top: 1px solid transparent;
    }
  }

  &__image {
    object-fit: cover;
    width: 100%;
    display: block;
    margin-bottom: 1em;
  }

  &__placeholder {
    background-color: $color-very-blog-grey;
  }

  &__body {
    margin-bottom: 3em;
  }

  &__link {
    display: flex;
    align-items: center;
    padding-left: 2.5em;
    text-decoration: none;
    border: none !important;

    &::before {
      content: '';
      display: block;
      height: 1px;
      width: 2em;
      position: absolute;
      top: 0.75em;
      left: 0;
      transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
      background: $color-orange;
    }
  }

  &__link--current {
    color: $color-orange;
  }

  &__meta {
    display: inline-block;
    font-size: 0.66em;
    color: $color-light-grey;
  }

  &__feature-image {
    margin-bottom: 2em;
    width: 100%;
  }

  &__content {
    @include bp(medium) {
      width: 65%;
      max-width: 800px;
    }

    img {
      height: auto;
    }
  }

  &__sidebar {
    position: sticky;
    top: 2em;
    align-self: flex-start;
    margin-top: 10em;

    @include bp(medium) {
      top: 10em;
      width: 25%;
    }
  }

  &__more-highlight {
    margin-bottom: 1em;
  }

  &__more-posts {
    margin: 0 0 5em;
    list-style-type: none;
    border-top: 1px solid $color-orange;
    padding-top: 0.25em;

    li {
      border-bottom: 1px solid $color-orange;
      padding-bottom: 0.25em;
    }

    a {
      padding: 0.5em;
      display: block;
      background: $color-very-blog-grey;
    }
  }

  &__pagination {
    display: flex;
    justify-content: space-between;
  }
}

.c-blog--16-9 .c-blog__image {
  aspect-ratio: 16 / 9;
}

.c-blog--1-1 .c-blog__image {
  aspect-ratio: 1 / 1.1;
}

@include bp(medium) {
  .c-blog--highlight {
    position: relative;
    background-color: $color-black;
    margin-bottom: 2em;

    a {
      display: block;
    }

    .c-blog__image {
      transition: $global-transition;
      opacity: 0.9;
    }

    .c-blog__title {
      color: $color-white;
      font-size: 1.75rem;
      font-weight: 400;
      line-height: 1.2;
    }

    .c-blog__body {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      display: block;
      padding: 2.5em;
      margin: 0;
    }

    &:hover .c-blog__image {
      opacity: 0.35;
    }
  }
}
