/* ==========================================================================
   #WRAPPER
   ========================================================================== */

/**
 * Page-level constraining and wrapping elements.
 */

.o-wrapper {
  @include clearfix();

  padding-left: 2.5rem;
  padding-right: 2.5rem;
  margin-right: auto;
  margin-left: auto;
  max-width: 1366px;

  @include bp(medium) {
    padding-left: 3.5rem;
    padding-right: 3.5rem;
  }
}
