/* ==========================================================================
   #HEADER
   ========================================================================== */

.c-header {
  @extend %u-pos--fixed;
  @extend %u-pos--br;

  background-color: rgba(27, 37, 53, 0.05);
  display: flex;
  justify-content: end;
  overflow-y: scroll;
  z-index: 9999;
  top: 0;

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &__wrapper {
    min-height: 100%;
    padding: 0 2.5rem;

    @include bp(small) {
      padding: 0 50px;
    }
  }
}

.c-header__item {
  @extend %u-pos--rel;
  @extend %u-pos--index-5;

  &:first-child {
    margin-top: 50px;
  }

  &:last-child {
    padding-bottom: 50px;
  }

  .tdc-logo {
    transition: $global-transition;
    fill: $color-dark-blue;
    width: 3.5em;

    @include bp(small) {
      width: 75px;
    }
  }
}

.c-header--hidden {
  background-color: transparent;
  height: 120px;

  &:hover {
    transition: width 300ms cubic-bezier(0.25, 0.46, 0.45, 0.94),
      background 300ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
    background: rgba(7, 17, 32, 0.95);
    height: 100%;

    .c-header__item .tdc-logo {
      fill: $color-white;

      @include bp(small) {
        width: 85px;
      }
    }
  }
}
