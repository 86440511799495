/* ==========================================================================
   #GRID
   ========================================================================== */

.o-grid {
  display: grid;
}

.o-grid--guttered-tiny {
  grid-gap: 0.5em;
}

.o-grid--guttered-small {
  grid-gap: 2em;
}

.o-grid--guttered {
  @include bp(medium) {
    grid-gap: 2em;
  }

  @include bp(full) {
    grid-gap: 4em;
  }
}

.o-grid--margin {
  @include bp(medium) {
    margin-left: 2em;
    margin-right: 2em;
  }

  @include bp(full) {
    margin-left: 4em;
    margin-right: 4em;
  }
}

.o-grid--stagger {
  > *:nth-child(even) {
    margin-top: 2em;
  }
}

.o-grid--2 {
  @include bp(medium) {
    grid-template-columns: repeat(2, 1fr);
  }

  &--mobile {
    grid-template-columns: repeat(2, 1fr);
  }
}

.o-grid--3 {
  @include bp(medium) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.o-grid--4 {
  @include bp(medium) {
    grid-template-columns: repeat(4, 1fr);
  }
}

.o-grid--5 {
  @include bp(medium) {
    grid-template-columns: repeat(5, 1fr);
  }
}
