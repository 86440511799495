/* ==========================================================================
   #FOOTER
   ========================================================================== */

.c-footer {
  background-color: $color-dark-blue;
  color: $color-grey-blue;
  overflow: hidden;

  &__cta {
    margin: 0 auto;
    width: 100%;
    max-width: 1366px;
    padding: 2.5rem;

    @include bp(medium) {
      padding: 3.5rem;
    }
  }

  &__details {
    margin: 0 auto;
    width: 100%;
    max-width: 1366px;
    padding: 2.5rem;

    @include bp(medium) {
      padding: 3.5rem;
    }
  }

  &__grid {
    @include bp-down(medium) {
      > * {
        margin-bottom: 2rem;
      }
    }

    @include bp(medium) {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      grid-gap: 2em;
    }
  }

  &__legals {
    color: rgba($color-grey-blue, 0.5);
  }

  &__link {
    color: $color-grey-blue !important;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  &__link-highlight {
    width: 100%;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      height: 1px;
      width: 100%;
      background: $color-orange;
      transition: $global-transition;
      bottom: 0;
    }

    &:hover {
      text-decoration: none;
    }
  }
}

.c-footer__details .c-footer__link {
  margin-right: 1rem;
  display: inline-flex;
}

.c-footer__menu {
  @include bp(large) {
    display: flex;
    flex-wrap: wrap;
  }
}

.c-footer__menu-item {
  @include bp(large) {
    width: 50%;
  }
}

.c-footer__menu-link {
  color: $color-orange;
}
