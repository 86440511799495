.c-sectors {
  align-items: flex-start;
}

.c-sectors__group {
  font-size: 0;
  margin: 0 auto;
  position: relative;
  aspect-ratio: 1 / 1.1;
  z-index: 0;

  &-item {
    position: absolute;
    border: 1px dashed $color-fill;
    display: inline-flex;
    border-radius: 50%;
    aspect-ratio: 1 / 1;
    font-size: 1rem;
    margin: 0;
    width: 61%;

    span {
      white-space: nowrap;
    }
  }

  &-item--innovation {
    top: 0;
    left: 19.5%;
  }

  &-item--design {
    top: 9%;
    left: 0;
  }

  &-item--creative {
    top: 9%;
    left: 39%;
  }

  &-item--technology {
    bottom: 9%;
    left: 0;
  }

  &-item--business {
    bottom: 9%;
    left: 39%;
  }

  &-item--culture {
    bottom: 0;
    left: 19.5%;
  }

  &-item--tdc {
    top: 45%;
    left: 45%;
    height: 10%;
    width: 10%;
    opacity: 0;
    position: absolute;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  .logo-container {
    border-bottom: 1px solid;
  }

  .logo-spacer {
    display: inline-block;
  }

  .inner {
    transform: translate(-50%, -50%);
    position: absolute;
    text-align: center;
    top: 50%;
    left: 50%;
    width: 30%;
  }
}

.c-sectors__copy {
  position: relative;
  z-index: 2;
}
