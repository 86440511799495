.u-copy {
  &--huge {
    font-size: 3.5em !important;
    line-height: 1.1 !important;
  }

  &--xl {
    font-size: 2.25rem;
    font-weight: 400;
    line-height: 1.2;
  }

  &--l {
    font-size: 1.75rem;
    font-weight: 400;
    line-height: 1.2;
  }

  &--m {
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 1.2;
  }

  &--s {
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.2;
  }

  &--p {
    font-size: 1rem;
    font-weight: normal;
    line-height: 1.5;
  }

  &--xs {
    font-size: 0.9rem;
  }

  &--opacity-half {
    opacity: 0.5;
  }
}
