.c-scroll {
  position: fixed;
  bottom: 20px;
  right: 20px;
  line-height: 0;

  @include bp-down(xlarge) {
    display: none;
  }

  .home & {
    display: none;
  }
}

.c-scroll__svg {
  width: 2em;
}

.c-scroll__arrow {
  position: absolute;
  animation: animate_arrow 5s infinite;
}

@keyframes animate_arrow {
  0% {
    opacity: 0;
    transform: translateY(-2px);
  }

  30% {
    opacity: 1;
    transform: translateY(0);
  }

  70% {
    opacity: 1;
    transform: translateY(0);
  }

  80% {
    opacity: 0;
    transform: translateY(4px);
  }

  100% {
    opacity: 0;
    transform: translateY(-2px);
  }
}
