/* ==========================================================================
   #PAGE
   ========================================================================== */

/**
 * Simple page-level setup.
 *
 * 1. Set the default `font-size` and `line-height` for the entire project.
 * 2. Force scrollbars to always be visible to prevent awkward ‘jumps’ when
 *    navigating between pages that do/do not have enough content to produce
 *    scrollbars naturally.
 * 3. Ensure the page always fills at least the entire height of the viewport.
 */

html {
  background: $color-bg;
  color: $color-text;
  font-weight: 400;
  font-family: $font-sans-serif;
  font-size: 1em; /* 16px */
  line-height: 1.5; /* [1] */
  min-height: 100%; /* [3] */
  overflow-y: scroll; /* [2] */
  overflow-x: hidden;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  margin-top: 100px;

  @media (any-pointer: fine) {
    cursor: none !important;
  }

  @include bp(large) {
    font-size: 1.125em; /* 18px */
    margin-top: 0;
  }

  @include bp(full) {
    font-size: 1.25em; /* 20px */
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
