.c-tease {
  opacity: 0;
  transform: translate(30px);
}

.c-tease--portrait {
  .c-tease__title {
    margin-top: 0.4rem;
    font-size: 1.25rem;
  }
}

.c-tease--hover {
  transition: $global-transition;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 40vh;

  @include bp(medium) {
    height: 30vw;
  }

  .c-tease__link {
    background: $color-dark-blue;
    position: relative;
    height: 100%;
    width: 100%;
    display: block;
  }

  .c-tease__media {
    height: 100%;
    width: 100%;
    object-fit: cover;
    opacity: 0.66;
    mix-blend-mode: luminosity;
    transition: opacity 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94),
      transform 5s linear;

    .vjs-tech {
      object-fit: cover;
    }
  }

  .c-tease__title {
    position: absolute;
    text-align: center;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    color: $color-white;
    padding: 0 10px;
    margin: 0;
    transition: $global-transition;
    transition-delay: 270ms;
  }

  .c-tease__more {
    font-size: 1rem;
    border-bottom: 1px solid white;
    display: inline-block;

    @include bp(medium) {
      @include linktransitiondefault;
    }
  }

  &:hover {
    .c-tease__media {
      mix-blend-mode: unset;
    }

    .c-tease__media-image {
      transform: scale(1.25) translateY(-20px);
    }

    .c-tease__more {
      @include bp(medium) {
        @include linktransitionhovered;
      }
    }
  }

  &.inactive {
    display: none;
  }

  &.active {
    opacity: 1 !important;
    transform: translate(0) !important;
  }
}
