%u-pad,
.u-pad {
  &--3-5 {
    padding: 2.5rem;

    @include bp(medium) {
      padding: 3.5rem;
    }
  }
}
